/* eslint-disable react/style-prop-object */
import React from 'react';
import { useForm } from 'react-hook-form';
import * as Sentry from '@sentry/react';
import { toast } from 'react-toastify';
import { format, parseISO } from 'date-fns';
import { useParams } from 'react-router-dom';
import {
  Asset,
  UpdateAssetCommand,
  useLockDataEntryAssetMutation,
  useUnlockDataEntryAssetMutation,
  useUpdateDataEntryAssetMutation,
} from '../../../generated/gql/types';
import DataEntryForm from './DataEntryForm';
import DataEntryButton from './DataEntryButton';
import { GoodCitySenseOrganizationIdHeader } from '../../../Global';
import { DataEntryAssetProperty } from '../../../AssetHelpers';

function DataEntryRecordEdit({
  orgId, asset, closeHandler, refetchQuery, isManager,
}: DataEntryRecordEditProps) {
  const params = useParams();

  const [updateAsset, { loading, error }] = useUpdateDataEntryAssetMutation(
    {
      context: {
        clientName: 'asset',
        headers: {
          [GoodCitySenseOrganizationIdHeader]: orgId,
        },
      },
    },
  );
  const notifySaved = () => toast.success('De aanpassingen aan de meting zijn opgeslagen', { autoClose: 7000 });
  const notifyLocked = () => toast.success('De meting is goedgekeurd', { autoClose: 7000 });
  const notifyUnlocked = () => toast.warning('Goedgekeurde meting is ongedaan gemaakt', { autoClose: 7000 });

  const [lockAsset, { loading: lockAssetLoading, error: lockAssetError }] = useLockDataEntryAssetMutation({
    context: {
      clientName: 'asset',
      headers: {
        [GoodCitySenseOrganizationIdHeader]: params.orgId,
      },
    },
    fetchPolicy: 'network-only',
  });

  const [unlockAsset, { loading: unlockAssetLoading, error: unlockAssetError }] = useUnlockDataEntryAssetMutation({
    context: {
      clientName: 'asset',
      headers: {
        [GoodCitySenseOrganizationIdHeader]: params.orgId,
      },
    },
    fetchPolicy: 'network-only',
  });

  const {
    register,
    unregister,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<UpdateAssetCommand>({});

  const onSubmit = handleSubmit((command) => {
    updateAsset({
      refetchQueries: [
        refetchQuery,
      ],
      variables: {
        command: {
          id: command.id,
          name: command.name,
          properties: command.properties?.filter((p) => p.value !== undefined && p.value !== '' && p.key !== undefined && p.type !== undefined)
            .map((property) => {
              if (property.key === DataEntryAssetProperty.SoilExtractionDate) {
                return { ...property, ...{ value: format(parseISO(property.value!), 'y-MM-dd') } };
              }

              return property;
            }),
        },
      },
    }).then(() => {
      closeHandler();
      notifySaved();
    }).catch((e) => {
      Sentry.captureMessage(e.toString(), 'error');
    });
  });

  const submitLockAsset = (assetId: string) => {
    // eslint-disable-next-line no-alert
    if (window.confirm('Weet je zeker dat je deze meting wilt goedkeuren?')) {
      lockAsset({
        refetchQueries: [
          refetchQuery,
        ],
        variables: {
          assetId,
        },
      }).then(() => {
        closeHandler();
        notifyLocked();
      }).catch((e) => {
        Sentry.captureMessage(e.toString(), 'error');
      });
    }
  };

  const submitUnlockAsset = (assetId: string) => {
    // eslint-disable-next-line no-alert
    if (window.confirm('Weet je zeker dat je de goedkeuring ongedaan wilt maken?')) {
      unlockAsset({
        refetchQueries: [
          refetchQuery,
        ],
        variables: {
          assetId,
        },
      }).then(() => {
        closeHandler();
        notifyUnlocked();
      }).catch((e) => {
        Sentry.captureMessage(e.toString(), 'error');
      });
    }
  };

  function renderLockButtons() {
    if (asset.lockedAt != null) {
      return (
        <DataEntryButton type="button" color="danger" onClick={() => submitUnlockAsset(asset.id)}>
          Goedkeuring ongedaan maken
        </DataEntryButton>
      );
    }
    return (
      <DataEntryButton type="button" color="success" onClick={() => submitLockAsset(asset.id)}>
        Meting Goedkeuren
      </DataEntryButton>
    );
  }

  return (
    <form className="flex flex-col min-h-full gap-y-3" onSubmit={onSubmit}>
      <input type="hidden" {...register('id', { value: asset.id })} />
      <input type="hidden" {...register('name', { value: asset.name ?? 'Soil measurement' })} />
      <DataEntryForm
        asset={asset}
        register={register}
        unregister={unregister}
        errors={errors}
        setValue={setValue}
        closeModal={closeHandler}
      />

      {(loading || lockAssetLoading || unlockAssetLoading) ? (
        <div className="flex justify-end items-center gap-6">
          {isManager && asset.lockedAt ? (
            <DataEntryButton type="button" color="danger" disabled>Goedkeuring ongedaan maken</DataEntryButton>
          ) : (
            <DataEntryButton type="button" color="success" disabled>Meting Goedkeuren</DataEntryButton>
          )}
          <DataEntryButton type="button" onClick={() => closeHandler()} color="blank">Annuleren</DataEntryButton>

          <DataEntryButton type="submit" color="info" disabled>Meting opslaan </DataEntryButton>
        </div>
      ) : (
        <div className="flex justify-end items-center gap-6">
          {isManager && (
            renderLockButtons()
          )}

          <DataEntryButton type="button" onClick={() => closeHandler()} color="blank">Annuleren</DataEntryButton>
          {asset?.lockedAt != null ? (
            <DataEntryButton type="button" color="info" disabled>Meting opslaan</DataEntryButton>
          ) : (
            <DataEntryButton type="submit" color="info">Meting opslaan</DataEntryButton>
          )}
        </div>
      )}

      {error && (
        <div className="text-red-600 my-2">
          Sorry er is iets misgegaan met het opslaan van je meting, probeer het later opnieuw.
        </div>
      )}

      {lockAssetError && (
        <div className="text-red-600 my-2">
          Sorry er is iets misgegaan met het goedkeuren van de meting, probeer het later opnieuw.
        </div>
      )}

      {unlockAssetError && (
        <div className="text-red-600 my-2">
          Sorry er is iets misgegaan met het ongedaan maken van de goedkeuring, probeer het later opnieuw.
        </div>
      )}
    </form>
  );
}

type DataEntryRecordEditProps = {
  orgId: string
  asset: Asset
  closeHandler: () => void
  refetchQuery: string
  isManager: boolean
};

export default DataEntryRecordEdit;
