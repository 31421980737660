import React from 'react';
import { useTranslation } from 'react-i18next';
import { format, parseISO } from 'date-fns';
import { nl } from 'date-fns/locale';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import {
  Asset,
  AssetsResult,
} from '../../../generated/gql/types';
import {
  DataEntryAssetProperty, Direction, getProperty, Sort, translateProperty,
} from '../../../AssetHelpers';
import ColumnSortButton from './ColumnSortButton';

function DataEntryTable({
  userId, assets, handleEdit, handleDelete, handleSelectAll, selectedItems, handleSelectItem, sort, setSort,
}: DateEntryTableProps) {
  const { t } = useTranslation();

  function renderTableHeader(propertyName: string) {
    return (
      <th
        key={propertyName}
        scope="col"
        className="px-4 text-[#686868] text-left font-medium capitalize text-[16px] py-3"
      >
        {sort.name === propertyName ? (
          <div className="flex items-center gap-x-2 whitespace-nowrap underline">
            {t(propertyName)}
            <ColumnSortButton
              sort={{
                name: propertyName,
                direction: sort.name === propertyName ? sort.direction : Direction.DESC,
              }}
              onChange={setSort}
            />
          </div>
        ) : (
          <div className="flex items-center gap-x-2 whitespace-nowrap">
            {t(propertyName)}
            <ColumnSortButton
              sort={{
                name: propertyName,
                direction: sort.name === propertyName ? sort.direction : Direction.DESC,
              }}
              onChange={setSort}
            />
          </div>
        )}
      </th>
    );
  }

  function renderReviewStatus(asset: Asset) {
    if (asset.lockedAt != null) {
      return (
        <>
          <FontAwesomeIcon className="text-gcs-success" size="lg" icon={icon({ name: 'check-circle' })} />
          {' '}
          Goedgekeurd
        </>
      );
    }

    const status = getProperty(asset, DataEntryAssetProperty.ReviewStatus)?.value ?? 'draft';
    if (status === 'draft') {
      return (
        <>
          {t(status)}
        </>
      );
    }

    if (status === 'amend') {
      return (
        <>
          <FontAwesomeIcon size="lg" icon={icon({ name: 'comments', style: 'regular', family: 'classic' })} />
          {' '}
          {t(status)}
        </>
      );
    }

    if (status === 'ready') {
      return (
        <>
          <FontAwesomeIcon size="xl" icon={icon({ name: 'file-search', style: 'light', family: 'classic' })} />
          {' '}
          {t(status)}
        </>
      );
    }

    return (
      <>
        {t(status)}
      </>
    );
  }

  return (
    <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
      <thead className="text-xs text-gray-700 uppercase bg-[#68686826]">
        <tr>
          <th
            scope="col"
            className="p-4"
          >
            <div className="flex items-center">
              <input
                id="checkbox-all"
                type="checkbox"
                className="w-5 h-5 text-[#235B60] bg-transparent border-[#686868] rounded focus:ring-[#235B60]"
                onChange={handleSelectAll}
                checked={selectedItems.length === (assets?.data ?? []).length}
              />
            </div>
          </th>

          {renderTableHeader(DataEntryAssetProperty.ProjectName)}
          {renderTableHeader(DataEntryAssetProperty.PHValue)}
          {renderTableHeader(DataEntryAssetProperty.OrganicMaterial)}
          {renderTableHeader(DataEntryAssetProperty.SoilType)}
          {renderTableHeader(DataEntryAssetProperty.ReviewStatus)}
          {renderTableHeader(DataEntryAssetProperty.PientereTuinenSerial)}
          {renderTableHeader('updated_at')}
          {renderTableHeader(DataEntryAssetProperty.SoilExtractionDate)}
          <th
            scope="col"
            className="px-4 py-3 text-left text-[#686868] capitalize font-medium text-[16px]"
          >
            Actie
          </th>
        </tr>
      </thead>
      <tbody className="text-left">
        {(assets?.data ?? []).map((asset) => (
          <tr
            key={asset.id}
            className={`${
              selectedItems.includes(asset) ? 'bg-[#D0EBEE]' : 'bg-white'
            }  dark:bg-gray-800 text-left dark:border-gray-700 hover:bg-[#D0EBEE] border-b border-[#68686880] `}
          >
            <td className="w-4 p-4">
              <div className="flex items-center">
                <input
                  type="checkbox"
                  className="w-5 h-5 text-[#235B60] bg-transparent border-[#686868] rounded focus:ring-[#235B60]"
                  checked={selectedItems.includes(asset)}
                  onChange={() => handleSelectItem(asset)}
                />
              </div>
            </td>
            <td
              className="px-4 py-4 text-[16px] text-left truncate font-normal text-gray-700 capitalize-first"
            >
              {(getProperty(asset, DataEntryAssetProperty.ProjectName)?.value ?? 'Onbekend').replaceAll('_', ' ')}
            </td>
            <td
              className="px-4 py-4 text-[16px] text-left truncate font-normal text-gray-700"
            >
              {getProperty(asset, DataEntryAssetProperty.PHValue)?.value}
            </td>
            <td
              className="px-4 py-4 text-[16px] text-left truncate font-normal text-gray-700"
            >
              {getProperty(asset, DataEntryAssetProperty.OrganicMaterial)?.value}
            </td>
            <td
              className="px-4 py-4 text-[16px] text-left truncate font-normal text-gray-700"
            >
              {translateProperty(asset, DataEntryAssetProperty.SoilType, t)}
            </td>
            <td
              className="px-4 py-4 text-[16px] text-left truncate font-normal text-gray-700"
            >
              {renderReviewStatus(asset)}
            </td>
            <td
              className="px-4 py-4 text-[16px] text-left truncate font-normal text-gray-700"
            >
              {getProperty(asset, DataEntryAssetProperty.PientereTuinenSerial)?.value ?? 'Onbekend'}
            </td>
            <td
              className="px-4 py-4 text-[16px] text-left truncate font-normal text-gray-700"
            >
              {format(parseISO(asset.updatedAt), 'dd-MM-y', { locale: nl })}
            </td>
            <td
              className="px-4 py-4 text-[16px] text-left truncate font-normal text-gray-700"
            >
              {getProperty(asset, DataEntryAssetProperty.SoilExtractionDate)?.value !== undefined ? (
                format(parseISO(getProperty(asset, DataEntryAssetProperty.SoilExtractionDate)?.value!), 'dd-MM-y', { locale: nl })
              ) : 'Onbekend'}
            </td>
            <td className="px-4 py-4 cursor-pointer gap-x-2 flex items-center">
              <button type="button" onClick={() => handleEdit(asset)}>
                <FontAwesomeIcon className="cursor-pointer text-gray-900" size="lg" icon={icon({ name: 'edit', style: 'regular', family: 'classic' })} />
              </button>
              {(getProperty(asset, DataEntryAssetProperty.UserId)?.value === userId && asset?.lockedAt === null) && (
                <button type="button" onClick={() => handleDelete(asset)}>
                  <FontAwesomeIcon
                    className="cursor-pointer text-gcs-danger"
                    size="lg"
                    icon={icon({ name: 'trash-alt', style: 'regular', family: 'classic' })}
                  />
                </button>
              )}
            </td>
          </tr>
        ))}
      </tbody>

    </table>
  );
}

export type DateEntryTableProps = {
  assets: AssetsResult | undefined,
  userId: string,
  handleSelectAll: (e: React.ChangeEvent<HTMLInputElement>) => void
  handleEdit: (item: Asset) => void
  handleDelete: (item: Asset) => void
  handleSelectItem: (item: Asset) => void
  selectedItems: Asset[]
  sort: Sort
  setSort: (sort: Sort) => void
};

export default DataEntryTable;
