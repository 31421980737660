import React, { ReactNode } from 'react';
import { Modal } from 'flowbite-react';

interface ReusableModalProps {
  isOpen: boolean;
  onClose: () => void;
  header?: string;
  children?: ReactNode;
  modalSize?: string;
}

function DataEntryModal({
  isOpen, onClose, header, children, modalSize,
}: ReusableModalProps) {
  return (
    <Modal
      dismissible
      size={modalSize}
      show={isOpen}
      onClose={onClose}
    >
      {header && (
        <Modal.Header>
          <span className="text-2xl font-medium text-gray-900">{header}</span>
        </Modal.Header>
      )}
      <Modal.Body>
        <div className="space-y-2">{children}</div>
      </Modal.Body>
      {/* <Modal.Footer className="flex px-10 justify-end gap-6">
        <CustomButton onClick={onClose} className="bg-white shadow-lg">Annuleren</CustomButton>
        <CustomButton className=" shadow-lg bg-[#237D86] text-white">Meting Toevoegen</CustomButton>
      </Modal.Footer> */}
    </Modal>
  );
}
export default DataEntryModal;
