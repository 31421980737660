/* eslint-disable react/style-prop-object */
import React from 'react';
import { LatLng } from 'leaflet';
import {
  format, isTomorrow, parseISO,
} from 'date-fns';
import { round } from 'lodash';
import { nl } from 'date-fns/locale';
import { Alert, Tooltip } from 'flowbite-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Forecast, useGetWeatherForecastQuery } from '../../../generated/gql/types';
import BoxElement from '../pientere-tuinen/BoxElement';
import WeatherIcon from './WeatherIcon';

function WeatherForecast({ coordinates }: WeatherForecastProps) {
  const { data: forecastData, loading: forecastLoading } = useGetWeatherForecastQuery();

  if (forecastLoading) {
    return null;
  }

  if (!forecastData || forecastData.weather.forecasts.length === 0) {
    return null;
  }

  const locations = forecastData.weather.forecasts.map((f) => ({
    name: f.name,
    distance: coordinates.distanceTo(new LatLng(Number(f.latitude), Number(f.longitude))),
  })).sort((a, b) => a.distance - b.distance);
  if (locations.length === 0) {
    return null;
  }

  const closestLocation = forecastData.weather.forecasts.find((f) => f.name === locations[0].name);
  if (!closestLocation) {
    return null;
  }

  const tomorrowForecast = closestLocation.forecasts.find((f) => isTomorrow(parseISO(f.date)));

  function showAlert(message: String) {
    return (
      <Alert
        color="info"
        theme={{ base: 'flex flex-col gap-2 p-2 text-sm' }}
      >
        <span>
          {message}
        </span>
      </Alert>
    );
  }

  function highProbabilityRain(forecast: Forecast) {
    return forecast?.rain && forecast?.rain > 3 && forecast?.pop && forecast.pop > 70;
  }

  function willFreeze(forecast: Forecast) {
    return forecast?.temperature && forecast.temperature < 7;
  }

  function isHot(forecast: Forecast) {
    return forecast?.temperature && forecast.temperature > 20;
  }

  return (
    <BoxElement>
      <div className="flex flex-col  gap-y-2">
        <div className="flex justify-between">
          <div className="flex flex-col gap-y-1">
            <div className="flex gap-1">
              <WeatherIcon iconCode={tomorrowForecast?.icon ?? '04d'} />
              <div className="text-5xl">
                {round(Number(tomorrowForecast?.temperature), 0)}
              </div>
              <div className="text-xl">&#8451;</div>

            </div>
            <div className="text-[8px] text-gray-300">
              Laatste update
              {' '}
              {format(parseISO(tomorrowForecast?.lastUpdated), 'dd-MM-y HH:mm:ss', { locale: nl })}
            </div>
          </div>

          <div className="flex flex-col">
            <div className="font-bold flex gap-x-1">
              Weer
              {' '}
              {closestLocation.name}
              <Tooltip
                content="Dichtstbijzijnde weerstation bij jouw sensor"
                style="light"
                className="!z-[9999]"
              >
                <FontAwesomeIcon icon={icon({ name: 'info-circle', family: 'classic', style: 'light' })} />
              </Tooltip>
            </div>
            <div
              className="capitalize-first text-sm"
            >
              Morgen
              {' '}
              (
              {format(parseISO(tomorrowForecast?.date), 'EEEE', { locale: nl })}
              )
            </div>
            <div className="capitalize-first text-sm">{tomorrowForecast?.description}</div>
          </div>
        </div>

        {tomorrowForecast && highProbabilityRain(tomorrowForecast) && (
          showAlert(`Er is morgen een grote kans dat er ${round(tomorrowForecast.rain ?? 3, 1)}mm regen valt. Het is niet nodig om je planten water te geven`)
        )}

        {tomorrowForecast && willFreeze(tomorrowForecast) && (
          showAlert('Er is morgen kans op vorst. Geef je planten alleen midden op de dag water als ze het nodig hebben')
        )}

        {tomorrowForecast && isHot(tomorrowForecast) && (
          showAlert('Het is morgen warm. Het beste moment om je planten water te geven is s\'ochtends')
        )}
      </div>
    </BoxElement>
  );
}

type WeatherForecastProps = {
  coordinates: LatLng
};

export default WeatherForecast;
