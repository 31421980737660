import React from 'react';

interface DataEntryButtonProps {
  children: React.ReactNode;
  iconProp?: JSX.Element;
  disabled?: boolean;
  onClick?: () => void;
  type?: 'button' | 'submit' | 'reset';
  color?: 'success' | 'danger' | 'info' | 'blank',
}

function DataEntryButton({
  children,
  iconProp,
  disabled = false,
  onClick,
  type = 'button',
  color = 'info',
}: DataEntryButtonProps) {
  if (color === 'success') {
    if (disabled) {
      return (
        <button
          type={type}
          className="group opacity-50 bg-gcs-success text-white border px-4 py-2 rounded-lg flex items-center gap-2 border-gray-400 text-sm font-medium"
          disabled={disabled}
          onClick={onClick}
        >
          {iconProp && iconProp}
          {children}
        </button>
      );
    }
    return (
      <button
        type={type}
        className="group bg-gcs-success text-white hover:bg-white hover:text-gcs-success border px-4 py-2 rounded-lg flex items-center gap-2 border-gray-400 text-sm font-medium"
        onClick={onClick}
      >
        {iconProp && iconProp}
        {children}
      </button>
    );
  }

  if (color === 'danger') {
    if (disabled) {
      return (
        <button
          type={type}
          className="group opacity-50 bg-gcs-danger text-white border px-4 py-2 rounded-lg flex items-center gap-2 border-gray-400 text-sm font-medium"
          disabled={disabled}
          onClick={onClick}
        >
          {iconProp && iconProp}
        </button>
      );
    }
    return (
      <button
        type={type}
        className="group bg-gcs-danger text-white hover:bg-white hover:text-gcs-danger border px-4 py-2 rounded-lg flex items-center gap-2 border-gray-400 text-sm font-medium"
        onClick={onClick}
      >
        {iconProp && iconProp}
        {children}
      </button>
    );
  }

  if (color === 'blank') {
    if (disabled) {
      return (
        <button
          type={type}
          className="group opacity-50 bg-white text-gcs-info border px-4 py-2 rounded-lg flex items-center gap-2 border-gray-400 text-sm font-medium"
          disabled={disabled}
          onClick={onClick}
        >
          {iconProp && iconProp}
          {children}
        </button>
      );
    }
    return (
      <button
        type={type}
        className="group bg-white text-gcs-info hover:bg-gcs-info hover:text-white border px-4 py-2 rounded-lg flex items-center gap-2 border-gray-400 text-sm font-medium"
        onClick={onClick}
      >
        {iconProp && iconProp}
        {children}
      </button>
    );
  }

  if (disabled) {
    return (
      <button
        type={type}
        className="group opacity-50 bg-gcs-info text-white border px-4 py-2 rounded-lg flex items-center gap-2 border-gray-400 text-sm font-medium"
        disabled={disabled}
        onClick={onClick}
      >
        {iconProp && iconProp}
        {children}
      </button>
    );
  }
  return (
    <button
      type={type}
      className="group bg-gcs-info text-white hover:bg-white hover:text-gcs-info border px-4 py-2 rounded-lg flex items-center gap-2 border-gray-400 text-sm font-medium"
      onClick={onClick}
    >
      {iconProp && iconProp}
      {children}
    </button>
  );
}

export default DataEntryButton;
